import { Component } from "angular-ts-decorators";
import { PracticePlansService } from "../praciteplans.service";
import { IComponentController } from "angular";
import { IMap } from "../../../models/IMap";
import { IPracticePlans } from "../ipracticeplans";
import template from "./planmanager.html";
import { IPracticePlanCategory } from "../ipracticeplancategory";
import _ from "lodash";
import "./planmanager.scss";
const selector: string = "planManager";

@Component({
  selector,
  template,
  bindings: {},
})
export class PlanManager implements IComponentController {
  static Name: string = selector;
  static $inject: string[] = ["PracticePlansService"];

  public categories: IPracticePlanCategory[];
  public plans: IPracticePlanCategory[];
  public selectedPlan: IPracticePlans;
  public backupPlan: IPracticePlans;

  constructor(private planService: PracticePlansService) { }

  $onInit() {
    this.planService.plansGroupedByCategory().then((groupedPlans) => {
      this.plans = groupedPlans;
    });
    this.planService.getCategories().then((categories) => {
      this.categories = categories;
    });
  }

  public selectPlan(plan: IPracticePlans) {
    this.selectedPlan = plan;
  }

  public cancel(plan: IPracticePlans) {
    this.selectedPlan = {} as IPracticePlans;
  }

  public removePlan(plan: IPracticePlans) {
    this.planService.removePlan(plan).then(() => {
      _.each(this.plans, (category: IPracticePlanCategory) => {
        let idx = category.practiceplans.indexOf(plan);
        if (idx >= 0) category.practiceplans.splice(idx, 1);
      });
      this.selectedPlan = {} as IPracticePlans;
    });
  }

  public savePlan(plan: IPracticePlans) {
    plan.categoryId = plan.category.id;
    let tempPlan = _.clone(plan);
    tempPlan.category = null;
    if (plan.id) {
      this.planService.updatePlan(tempPlan).then((savedPlan) => { });
    } else {
      this.planService.createPlan(tempPlan).then((savedPlan) => {
        let category: IPracticePlanCategory = _.find(this.plans, (p) => {
          return p.id === savedPlan.categoryId;
        });
        if (category) {
          category.practiceplans.push(savedPlan);
        }
      });
    }
    this.selectedPlan = {} as IPracticePlans;
  }

  public keys(obj) {
    return obj ? Object.keys(obj) : [];
  }

  public nameLabel(category: IPracticePlanCategory): string {
    return category && category.name;
  }
}
