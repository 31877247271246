import { NgModule } from "angular-ts-decorators";
import { RegistrationSystem } from "./registrationsystem/registrationsystem";
import { MessagesComponent } from "./messages/messages.component";
import { LeagueHomeComponent } from "./home/home";
import { CreateRegistration } from "./registrationsystem/create/create";
import { CreateTeam } from "./registrationsystem/createteam/createteam";
import { StateProvider, StateService, Transition } from "@uirouter/angularjs";
import "angular-wizard";
import "angular-wizard/dist/angular-wizard.css";
import { RegistrationList } from "./registrationsystem/list/list";
import { RegistrationSystemService } from "../../services/RegistrationSystemService";
import { LeagueWaiverService } from "./league.waiver.service";
import { EmergencyContact } from "./emergencycontact/emergencycontact";
import { LeagueRosterService } from "./league.roster.service";
import { IRosterEntry } from "../../models/IRosterEntry";
import { SeasonService } from "../../services/SeasonService";
import { RegisterForSeason } from "./registrationsystem/registerforseason/registerforseason";
import { LeagueContactsComponent } from "./contacts/contacts";
import { PaymentDashboard } from "./paymentdashboard/payment.dashboard.component";
import { ILeague } from "../../models/ILeague";
import { PaymentTable } from "./paymentdashboard/payment.table.component";
import { LeagueService } from "../../services/LeagueService";
import { IUser } from "../../models/IUser";
import { UserService } from "../../services/UserService";
import { PermissionsService } from "../../services/PermissionsService";
import { StandingsComponent } from './standings/standings';
import 'ui-select';
import { LeagueConfigService } from "../../services/LeagueConfigService";

const LeagueModuleName: string = "league-manager-module";

@NgModule({
  id: LeagueModuleName,
  declarations: [
    RegistrationSystem,
    CreateRegistration,
    CreateTeam,
    MessagesComponent,
    LeagueHomeComponent,
    RegistrationList,
    EmergencyContact,
    RegisterForSeason,
    LeagueContactsComponent,
    PaymentDashboard,
    PaymentTable,
    StandingsComponent,
  ],
  providers: [LeagueWaiverService, LeagueRosterService, LeagueWaiverService],
  imports: [
    'ui.select', 'ngSanitize'
  ],
})
export class LeagueModule {
  static Name: string = LeagueModuleName;

  public static config($stateProvider: StateProvider) {
    $stateProvider
      .state({
        name: "league.registration",
        url: "/registration",
        component: RegistrationSystem.Name,
        abstract: true,
      })
      .state({
        name: "league.registration.list",
        url: "",
        component: RegistrationList.Name,
      })
      .state({
        name: "league.registration.create",
        url: "/create",
        component: CreateRegistration.Name,
      })
      .state({
        name: "league.registration.createteam",
        url: "/createteam",
        component: CreateTeam.Name,
      })
      .state({
        name: "league.playoffstandings",
        url: "/playoffstandings",
        component: StandingsComponent.Name,
        resolve: {
          league: ["resource", (resource: ILeague) => resource],
          adminMode: [() => false],
          sortingConfig: ['LeagueConfigService', 'resource', (leagueConfigService: LeagueConfigService, resource: ILeague) => {
            return leagueConfigService.getByKey(resource.id, 'sortingRules');
          }]
        },
      })
      .state({
        name: "league.reports.playoffstandings",
        url: "/playoffstandings",
        component: StandingsComponent.Name,
        resolve: {
          league: ["resource", (resource: ILeague) => resource],
          adminMode: [() => true],
          sortingConfig: ['LeagueConfigService', 'resource', (leagueConfigService: LeagueConfigService, resource: ILeague) => {
            return leagueConfigService.getByKey(resource.id, 'sortingRules');
          }]
        },
      })
      .state({
        name: "league.reports.paymentdashboard",
        url: "/paymentdashboard",
        component: PaymentDashboard.Name,
        resolve: {
          user: [
            "user",
            "UserService",
            "$state",
            (
              user: IUser,
              permissioNService: PermissionsService,
              $state: StateService
            ) => {
              if (!user) {
                $state.go("league.home");
              }
            },
          ],
          league: ["resource", (resource: ILeague) => resource],
          seasons: [
            "league",
            "LeagueService",
            (league: ILeague, leagueService: LeagueService) => {
              return leagueService.getSeasons(league);
            },
          ],
        },
      })
      .state({
        name: "league.registerforupcomingseason",
        url: "/register",
        component: RegisterForSeason.Name,
        resolve: {
          season: [
            "leagueId",
            "SeasonService",
            (leagueId: string, seasonService: SeasonService) => {
              return seasonService.getOpenRegistrationSeason(leagueId);
            },
          ],
          agegroups: [
            "leagueId",
            "SeasonService",
            (leagueId: string, seasonService: SeasonService) => {
              return seasonService
                .getOpenRegistrationSeason(leagueId)
                .then((season) => {
                  if (season) {
                    return seasonService.getAgeGroups(season.id);
                  }
                });
            },
          ],
        },
      })
      .state({
        name: "league.registerforseason",
        url: "/register/:seasonId",
        component: RegisterForSeason.Name,
        resolve: {
          season: [
            "SeasonService",
            "$transition$",
            (seasonService: SeasonService, $transition$: Transition) => {
              return seasonService.get($transition$.params().seasonId);
            },
          ],
          agegroups: [
            "SeasonService",
            "$transition$",
            (seasonService: SeasonService, $transition$: Transition) => {
              return seasonService.getAgeGroups($transition$.params().seasonId);
            },
          ],
        },
      })
      .state({
        name: "league.emergencycontact",
        url: "/emergencycontact/:roster",
        component: EmergencyContact.Name,
        resolve: {
          showPlayer: () => true,
          afterState: () => "league.home",
          rosterEntry: [
            "LeagueRosterService",
            "$transition$",
            (
              leagueRosterService: LeagueRosterService,
              $transition$: Transition
            ) => {
              return leagueRosterService.getRosterEntry(
                $transition$.params().roster
              );
            },
          ],
        },
      })
      .state({
        name: "league.reports.contacts",
        url: "/contacts/:seasonId",
        params: { seasonId: null },
        component: LeagueContactsComponent.Name,
        resolve: {
          agegroups: [
            "SeasonService",
            "$transition$",
            (seasonService: SeasonService, $transition$: Transition) => {
              return seasonService.getAgeGroups($transition$.params().seasonId);
            },
          ],
          league: ["resource", (resource: ILeague) => resource],
        },
      });
  }
}
