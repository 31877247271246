import { Component, Input } from "angular-ts-decorators";
import { IAgeGroup } from "../../../models/IAgeGroup";
import { ILeague } from "../../../models/ILeague";
import { ISeason } from "../../../models/ISeason";
import { ILeagueConfig, ISortingRule, ISortingRules } from "../../../models/ISortingRules";
import { ITeamLevel } from "../../../models/ITeamLevel";
import { ITeamRegistration } from "../../../models/ITeamRegistration";
import { ITeamRegistrationGroup } from "../../../models/ITeamRegistrationGroup";
import { AgeGroupService } from "../../../services/AgeGroupService";
import { LeagueConfigService } from "../../../services/LeagueConfigService";
import { SortingAlgorithmFactory } from "../../../services/SortingAlgorithmFactory";
import { StandingsService } from "../../../services/StandingsService";
import template from "./standings.html";

const name: string = "stPlayoffStandings";

type StandingsItem = {
  team: string;
  wins: number;
  losses: number;
  ties: number;
  pwr: number;
  wlp: number;
  gb: number;
  pf: number;
  pa: number;
  rpi: number;
}

type TeamLevelGroup = {
  level: string;
  teams: StandingsItem[]
}

@Component({
  selector: name,
  template,
})
export class StandingsComponent {
  static Name: string = name;
  static $inject: string[] = ['AgeGroupService', 'StandingsService', 'SortingAlgorithmFactory', 'LeagueConfigService'];

  @Input() season: ISeason;
  @Input() agegroups: IAgeGroup[];
  @Input() resource: ILeague;
  @Input() adminMode: boolean;
  private _sortingConfig: ILeagueConfig<ISortingRules>;
  @Input()
  set sortingConfig(value: ILeagueConfig<ISortingRules>) {
    this._sortingConfig = value;
    if (value?.id) {
      this.sortingRules = value.value;
      this.sortingRules.rules?.forEach((rule) => {
        const idx = this.sortingOptions.findIndex((row) => row.property === rule.property);
        if (idx !== undefined && idx >= 0) {
          this.sortingOptions[idx].selected = true;
        }
      })
    }
  }
  get sortingConfig() {
    return this._sortingConfig;
  }

  selectedAgeGroup: IAgeGroup;
  public csvContent: string;
  public allColumns = [
    { field: "team", label: "team" },
    { field: "wins", label: "w" },
    { field: "losses", label: "l" },
    { field: "ties", label: "ties" },
    { field: "pwr", label: "pwr" },
    { field: "wlp", label: "w%" },
    { field: "pf", label: "pf" },
    { field: "pa", label: "pa" },
  ]

  public sortingRules = { excludeTournaments: true, rules: [] } as ISortingRules;
  public sortingOptions = [
    { label: 'power', property: 'pwr', direction: 'desc', selected: false },
    { label: 'rpi', property: 'rpi', direction: 'desc', selected: false },
    { label: 'wins', property: 'wins', direction: 'desc', selected: false },
    { label: 'losses', property: 'losses', direction: 'asc', selected: false },
    { label: 'ties', property: 'ties', direction: 'desc', selected: false },
    { label: 'head to head', property: 'hth', direction: 'desc', selected: false },
    { label: 'head to head 2 teams only', property: 'hth2o', direction: 'desc', selected: false },
    { label: 'points for', property: 'pf', direction: 'desc', selected: false },
    { label: 'points allowed', property: 'pa', direction: 'asc', selected: false },
    { label: 'points allowed average', property: 'paa', direction: 'asc', selected: false }
  ];

  public selectedColumns = [
    ...this.allColumns.slice(0, 9)
  ]

  standings: TeamLevelGroup[];
  constructor(private ageGroupService: AgeGroupService, private standingsService: StandingsService, private sortingAlgorithmFactory: SortingAlgorithmFactory, private leagueConfigService: LeagueConfigService) {
  }

  $onInit() {
    console.log(this.resource.id);
  }

  private teamLevels: ITeamLevel[]

  calcStandings() {
    this.standings = this.teamLevels.map((teamLevel) => {
      const allTeams = teamLevel.divisions.flatMap((division) => {
        return division.teamregistrations
      });
      const standingsOfTeams = this.standingsService.calculateStandingsOfTeamRegistrations(allTeams, this.sortingRules);
      const sortedStandings = this.sortingAlgorithmFactory.createDyanmicSortingAlgorithm(this.sortingRules).sortStandings(standingsOfTeams)
      const formattedStandings = sortedStandings.map((reg) => {
        const { wins, losses, ties, gb, wlp, pf, pa, pwr, rpi, team } = reg;
        return {
          team: team?.name,
          wins,
          losses,
          ties,
          gb,
          wlp,
          pf,
          pa,
          pwr,
          rpi
        }
      })

      return {
        level: teamLevel.name,
        teams: formattedStandings
      }
    });
  }

  async loadStandings(ageGroup: IAgeGroup) {
    this.teamLevels = await this.ageGroupService.getTeamsForStandings(ageGroup);
    this.calcStandings();
  }

  ageGroupChange(ageGroup: IAgeGroup) {
    this.selectedAgeGroup = ageGroup;
    this.loadStandings(ageGroup)
  }

  async saveSorting() {
    if (this.sortingConfig?.id) {
      const result = await this.leagueConfigService.update({
        ...this.sortingConfig,
        value: this.sortingRules
      });
    } else {
      const result = await this.leagueConfigService.add(this.resource.id, {
        key: 'sortingRules',
        value: this.sortingRules
      })
      this.sortingConfig = result;
    }
  }

  updateSortOrder() {
    this.saveSorting();
    this.calcStandings();
  }

  updateSortingOptions(option: ISortingRule) {
    if (option.selected) {
      this.sortingRules.rules?.push(option);
    } else {
      let idx = this.sortingRules.rules?.findIndex((row) => row.property === option.property);
      if (idx !== undefined && idx >= 0) this.sortingRules.rules?.splice(idx, 1);
    }
    this.saveSorting();
    this.calcStandings();
  }

  buildCsv(teams: StandingsItem[]) {
    let cols = [
      ...this.selectedColumns.map((col) => { return col.field })
    ];
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += cols.join(",") + "\r\n";
    teams.forEach((row: any) => {
      let values = [];
      for (let col of cols) {
        values.push(row[col]);
      }
      csvContent += values.join(",") + "\r\n";
    });
    return encodeURI(csvContent);
  }

  download(level: string, teams: StandingsItem[]) {
    let link = document.createElement("a");
    link.setAttribute("href", this.buildCsv(teams));
    link.setAttribute(
      "download",
      `export_${this.season.name}_${level}.csv`
    );
    document.body.appendChild(link);
    link.click();
  }
}
