import { IDateTime } from "../models/IDateTime";
import { IFacility } from "../models/IFacility";
import { IGame } from "../models/IGame";
import { Injectable } from "angular-ts-decorators";
import { IHttpService } from "angular";

@Injectable("GameEditorService")
export class GameEditorService {
  static $inject = ["$http", "$uibModal"];
  constructor(
    private $http: IHttpService,
    private $uibModal: angular.ui.bootstrap.IModalService,
  ) { }

  async editLocation(game: IGame, facilities: IFacility[]): Promise<IGame> {
    const result = await this.$uibModal.open({
      template: require("../templates/gameeditor/gameeditor.html").default,
      size: "lg",
      resolve: {
        game() {
          return game;
        },
        facilities() {
          return facilities;
        }
      },

      /* tslint:disable-next-line:no-shadowed-variable */
      controller: [
        "game",
        "facilities",
        function (game, facilities) {
          this.game = game;
          this.facilities = facilities;
          this.notTaken = function (date: IDateTime) {
            return !date.taken;
          };
        }
      ],
      controllerAs: "gameeditor"
    }).result;

    const newDate = await (game.date
      ? this.$http.patch<IDateTime>(`/api/datetimes/${game.date.id}`, {
        datetime: result.datetime,
        facilityId: result.facility.id
      })
      : this.$http.post<IDateTime>(`/api/datetimes`, {
        datetime: result.datetime,
        facilityId: result.facility.id
      })
    );
    game.date = newDate.data;
    const gameUpdate = await this.$http.patch<IGame>(`/api/games/${game.id}`, {
      dateId: newDate.data.id
    });
    return gameUpdate.data;
  }

  async scoreGame(game: IGame): Promise<IGame> {
    const result: IGame = await this.$uibModal.open({
      template: require("../templates/gameeditor/gamescore.html").default,
      size: "sm",
      resolve: {
        game() {
          return game;
        }
      },
      /* tslint:disable-next-line:no-shadowed-variable */
      controller: [
        "game",
        function (game: IGame) {
          this.game = game;
          this.homeTeamScore = game.homeTeamScore;
          this.awayTeamScore = game.awayTeamScore;
        }
      ],
      controllerAs: "gamescore"
    }).result;
    const response = await this.$http.patch<IGame>(`/api/games/${game.id}`, {
      homeTeamScore: result.homeTeamScore >= 0 ? result.homeTeamScore : null,
      awayTeamScore: result.awayTeamScore >= 0 ? result.awayTeamScore : null
    })
    game.homeTeamScore = response.data.homeTeamScore;
    game.awayTeamScore = response.data.awayTeamScore;
    return game;
  }
}
