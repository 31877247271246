// ...
const __webpack_nonce__ = 'c29tZSBjb29sIHN0cmluZyB3aWxsIHBvcCB1cCAxMjM=';
// ...


import _ from "lodash";
import { GridModule } from "./components/grid/grid.module";
import {
  StateProvider,
  StateService,
  UrlService,
  StateParams
} from "@uirouter/angularjs";
import { NgModule, platformBrowserDynamic } from "angular-ts-decorators";
import { ServicesModule } from "./services/Services.module";
import { Components } from "./components/Components.module";
// import { OrganizationComponents } from "./components/organization/organization.components";
import { States } from "./States";
import { TeamSiteStates } from "./TeamSiteStates";
// import { OrganizationStates } from "./OrganizationStates";
import { IRootStateService } from "./models/IRootStateService";
import { ILeagueWindowService } from "./models/ILeagueWindowService";
import { AdminModule } from "./components/admin/admin.module";
export const AppModuleName: string = "league-manager";
import { IProvider } from "restangular";
import angular, { IModule, IWindowService } from "angular";
//import ngSanitize from "angular-sanitize";
import { LeagueModule } from "./modules/leauge/league.module";
import { WizardModule } from "./components/wizard/wizard.module";
import { TeamSites } from "./teamsites";
import { PracticePlansModule } from "./modules/practiceplans/practiceplans.module";
import { WaiverModule } from "./modules/waivers/waivers";
import { TeamModule } from "./modules/team/team";

angular["lowercase"] = (text: string) => text?.toLowerCase();

@NgModule({
  id: "league-manager",
  declarations: [],
  imports: [
    "ui.router",
    "restangular",
    "pickadate",
    "textAngular",
    //ngSanitize,
    "ngAnimate",
    "ngMessages",
    "angular-loading-bar",
    "ui.bootstrap",
    "ui.mask",
    "ngFileUpload",
    "cgNotify",
    "angulartics",
    "angulartics.google.tagmanager",
    "LocalStorageModule",
    "ui.calendar",
    "ui.sortable",
    "mp.colorPicker",
    Components,
    ServicesModule,
    // OrganizationComponents,
    AdminModule,
    GridModule,
    LeagueModule,
    WizardModule,
    TeamSites,
    PracticePlansModule,
    WaiverModule,
    TeamModule
  ]
})
export class Main {
  constructor() {
    let module: IModule = angular.module(AppModuleName);
    module.filter("pagination", function () {
      return function (input, start) {
        start = +start;
        return input.slice(start);
      };
    });

    module.directive("updateTitle", [
      "$rootScope",
      "$timeout",
      "$transitions",
      function ($rootScope, $timeout, $transitions) {
        return {
          link(scope, element, attrs) {
            let listener = (transition) => {
              let title = attrs.defaultTitle;
              let data = transition.to().data;
              if (data && data.pageTitle)
                title = title + " : " + data.pageTitle;
              $timeout(
                () => {
                  element.text(title);
                },
                0,
                false
              );
            };

            $transitions.onSuccess({}, listener);
          }
        };
      }
    ]);
  }

  static config(
    $stateProvider: StateProvider,
    $urlServiceProvider: UrlService,
    $locationProvider: ng.ILocationProvider,
    $httpProvider: ng.IHttpProvider,
    RestangularProvider: IProvider,
    $compileProvider: ng.ICompileProvider,
    $windowProvider: any
  ) {
    $compileProvider.aHrefSanitizationWhitelist(
      /^\s*(https?|ftp|mailto|webcal|tel):/
    );
    $compileProvider.debugInfoEnabled(false);
    $locationProvider
      .html5Mode({
        enabled: true,
        requireBase: true,
        rewriteLinks: false
      })
      .hashPrefix("");

    $urlServiceProvider.rules.when("/league/hel", "/league/help");
    let $window = $windowProvider.$get() as ILeagueWindowService;
    if ($window.location.hostname.includes('.teams.')) {
      TeamSiteStates.Config($stateProvider, $urlServiceProvider);
    } else {
      States.Config($stateProvider, $urlServiceProvider);
    }

    RestangularProvider.setBaseUrl("/api");
    $httpProvider.interceptors.push([
      "$q",
      "$injector",
      function (
        $q: ng.IQService,
        $injector: angular.auto.IInjectorService
      ): ng.IHttpInterceptor {
        return {
          responseError(rejection): ng.IPromise<any> {
            let notify: angular.cgNotify.INotifyService = $injector.get(
              "notify"
            ) as angular.cgNotify.INotifyService;
            if (rejection.status === 401) {
              if (
                !(
                  rejection.data &&
                  rejection.data.error &&
                  rejection.data.error.code &&
                  (rejection.data.error.code === "LOGIN_FAILED" ||
                    rejection.data.error.code ===
                    "LOGIN_FAILED_EMAIL_NOT_VERIFIED" ||
                    rejection.data.error.code === "AUTHORIZATION_REQUIRED")
                )
              ) {
                notify({
                  message: "Sorry, you were not authorized to do that",
                  classes: "message-danger"
                });
              }
            } else if (rejection.status === 422) {
              let message =
                rejection &&
                  rejection.data &&
                  rejection.data.error &&
                  rejection.data.error.message
                  ? rejection.data.error.message
                  : "unkown";
              notify({
                message:
                  "There was an error processing that last request : " +
                  message,
                classes: "message-danger"
              });
            }
            return $q.reject(rejection);
          }
        };
      }
    ]);
  }
  static run(
    $rootScope: IRootStateService,
    $state: StateService,
    $stateParams: StateParams,
    $window: IWindowService
  ) {
    $rootScope.$state = $state;
    //$rootScope.themeUrl = `theme.css?host=${$window.location.hostname}`
    $rootScope.$stateParams = $stateParams;
  }
}

platformBrowserDynamic().bootstrapModule(Main);
