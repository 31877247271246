import { IGame } from '../models/IGame';
import { IDivision } from '../models/IDivision';
import { ITeamRegistration } from '../models/ITeamRegistration';
import { ITeamPromise } from '../models/ITeamPromise';
import { ITeamLevel } from '../models/ITeamLevel';
import { ISortingRules, ISortingRule } from '../models/ISortingRules';
import _ from 'lodash';
import moment from 'moment';

import { Injectable } from 'angular-ts-decorators';
import { IHttpService } from 'angular';
import { IFilter } from '../models/IFilter';

@Injectable('StandingsService')
export class StandingsService {
    static $inject = ['$http'];
    constructor(private $http: IHttpService) {

    }


    /**
     * calculates the statistics of the team for a given game
     */
    public calculateStatisticsOfMatch(teamregistration: ITeamRegistration, game: IGame) {
        if (!teamregistration || !game) return;
        if (teamregistration.id === game.homeTeamId) {
            if (game.homeTeamForfeit || game.awayTeamForfeit) {
                teamregistration.wins += game.awayTeamForfeit ? 1 : 0;
                teamregistration.losses += game.homeTeamForfeit ? 1 : 0;
            } else if (game.awayTeamScore != null && game.homeTeamScore != null) {
                teamregistration.hgp++;
                teamregistration.pf += +game.homeTeamScore;
                teamregistration.pa += +game.awayTeamScore;
                if (game.awayTeamScore === game.homeTeamScore) {
                    teamregistration.ties++;
                } else if (game.awayTeamScore < game.homeTeamScore) {
                    game.winner = game.homeTeamId;
                    teamregistration.wins++;
                    teamregistration.hw++;
                } else if (game.awayTeamScore > game.homeTeamScore) {
                    game.winner = game.awayTeamId;
                    teamregistration.losses++;
                    teamregistration.hl++;
                }
            }
        } else if (teamregistration.id === game.awayTeamId) {
            if (game.homeTeamForfeit || game.awayTeamForfeit) {
                teamregistration.wins += game.homeTeamForfeit ? 1 : 0;
                teamregistration.losses += game.awayTeamForfeit ? 1 : 0;
            } else if (game.awayTeamScore != null && game.homeTeamScore != null) {
                teamregistration.agp++;
                teamregistration.pf += +game.awayTeamScore;
                teamregistration.pa += +game.homeTeamScore;
                if (game.awayTeamScore === game.homeTeamScore) {
                    teamregistration.ties++;
                } else if (game.awayTeamScore > game.homeTeamScore) {
                    game.winner = game.awayTeamId;
                    teamregistration.wins++;
                    teamregistration.aw++;
                } else if (game.awayTeamScore < game.homeTeamScore) {
                    game.winner = game.homeTeamId;
                    teamregistration.losses++;
                    teamregistration.al++;
                }
            }
        }
    }

    private average(numbers: number[]) {
        if (!numbers || !numbers.length) {
            return 0;
        }
        return numbers.reduce((a, b) => a + b) / numbers.length;
    }

    /**
     * standard head to head algorithm, if there are multiple teams with the same record
     * it is determined if they have played any other tied team and gives a point for each win
     */
    public calculateHeadToHeadStandard(ties: _.Dictionary<ITeamRegistration[]>, firstNGames?: number) {
        _.each(ties, (tiedTeams: ITeamRegistration[], key: string) => {
            _.each(tiedTeams, (teamRegistration: ITeamRegistration) => {
                _.each(teamRegistration.homeGames, (g: IGame) => {
                    let opponentIsTied = _.find(tiedTeams, (tiedTeam: ITeamRegistration) => {
                        return tiedTeam.id === (g.awayTeam && g.awayTeam.id);
                    });
                    if (opponentIsTied) {
                        if (g.awayTeamForfeit) {
                            teamRegistration.hth++;
                        } else if (g.homeTeamScore > g.awayTeamScore) {
                            teamRegistration.hth++;
                        }
                    }
                });
                _.each(teamRegistration.awayGames, (g: IGame) => {
                    let opponentIsTied = _.find(tiedTeams, (tiedTeam: ITeamRegistration) => {
                        return tiedTeam.id === (g.homeTeam && g.homeTeam.id);
                    });
                    if (opponentIsTied) {
                        if (g.homeTeamForfeit) {
                            teamRegistration.hth++;
                        } else if (g.awayTeamScore > g.homeTeamScore) {
                            teamRegistration.hth++;
                        }
                    }
                });
            });
        });
    }

    /**
     * standard head to head algorithm, if there are multiple teams with the same record
     * it is determined if they have played any other tied team and gives a point for each win
     */
    public calculateHeadToHeadTwoOnly(ties: _.Dictionary<ITeamRegistration[]>, firstNGames?: number) {
        _.each(ties, (tiedTeams: ITeamRegistration[], key: string) => {
            if (tiedTeams && tiedTeams.length !== 2) return true; // only calculate if there are 2 teams only that are tied
            _.each(tiedTeams, (teamRegistration: ITeamRegistration) => {
                _.each(teamRegistration.homeGames, (g: IGame) => {
                    let opponentIsTied = _.find(tiedTeams, (tiedTeam: ITeamRegistration) => {
                        return tiedTeam.id === (g.awayTeam && g.awayTeam.id);
                    });
                    if (opponentIsTied) {
                        if (g.awayTeamForfeit) {
                            teamRegistration.hth2o++;
                        } else if (g.homeTeamScore > g.awayTeamScore) {
                            teamRegistration.hth2o++;
                        }
                    }
                });
                _.each(teamRegistration.awayGames, (g: IGame) => {
                    let opponentIsTied = _.find(tiedTeams, (tiedTeam: ITeamRegistration) => {
                        return tiedTeam.id === (g.homeTeam && g.homeTeam.id);
                    });
                    if (opponentIsTied) {
                        if (g.homeTeamForfeit) {
                            teamRegistration.hth2o++;
                        } else if (g.awayTeamScore > g.homeTeamScore) {
                            teamRegistration.hth2o++;
                        }
                    }
                });
            });
        });
    }

    private calculateGamesBack(teamregistrations: ITeamRegistration[]) {
        let sortedTeams = _.orderBy(teamregistrations, ['wins', 'losses', 'ties'], ['desc', 'asc', 'desc']);
        sortedTeams.forEach((value, index) => {
            if (index === 0) {
                return;
            }
            value.gb = ((sortedTeams[0].wins - value.wins) + (value.losses - sortedTeams[0].losses)) / 2;
        });
        return sortedTeams;
    }

    private filterFirstNGames(combinedGames: IGame[], firstNGames: number) {
        combinedGames = _.sortBy(combinedGames, function (g: IGame) {
            return g.date && g.date.datetime;
        });
        combinedGames = _.filter(combinedGames, function (g: IGame) {
            return g.homeTeamScore >= 0 && g.awayTeamScore >= 0;
        });
        return _.take(combinedGames, firstNGames);
    }
    public calculateStandingsOfTeamRegistrations(teamregistrations: ITeamRegistration[], sortingRules?: ISortingRules): ITeamRegistration[] {
        let me = this;
        _.each(teamregistrations, function (teamregistration: ITeamRegistration) {
            teamregistration.wins = 0;
            teamregistration.losses = 0;
            teamregistration.ties = 0;
            teamregistration.pf = 0;
            teamregistration.pa = 0;
            teamregistration.hth = 0;
            teamregistration.hth2o = 0;
            teamregistration.hgp = 0;
            teamregistration.agp = 0;
            teamregistration.hw = 0;
            teamregistration.aw = 0;
            teamregistration.hl = 0;
            teamregistration.al = 0;
            let combinedGames = _.concat(teamregistration.homeGames, teamregistration.awayGames);
            teamregistration.games = combinedGames.length;

            if (sortingRules && sortingRules.excludeTournaments) {
                combinedGames = _.filter(combinedGames, function (g: IGame) {
                    return !g.isTournamentMatch;
                });
            }

            if (sortingRules && sortingRules.firstGames > 0) {
                combinedGames = me.filterFirstNGames(combinedGames, sortingRules.firstGames);
            }

            _.each(combinedGames, (game: IGame) => {
                me.calculateStatisticsOfMatch(teamregistration, game);
            });

            let sortedGames = _.orderBy(combinedGames, (game: IGame) => {
                return game && game.date && game.date.datetime;
            });
            teamregistration.nextGame = _.find(
                sortedGames,
                (game: IGame) => {
                    let today = new Date();
                    return game && game.date && moment(game.date.datetime).toDate() > today;
                });

            teamregistration.previousGame = _.findLast(sortedGames, (game: IGame) => {
                let today = new Date();
                return game && game.date && moment(game.date.datetime).toDate() < today;
            });

            teamregistration.points = teamregistration.wins * 2 + teamregistration.ties * 1;
            teamregistration.wlp = (teamregistration.wins + teamregistration.ties * .5) / (teamregistration.wins + teamregistration.losses + teamregistration.ties) || 0;
            teamregistration.paa = teamregistration.pa / (teamregistration.wins + teamregistration.losses + teamregistration.ties);
        });
        let tieBreakerValues: ISortingRule[] = [];
        tieBreakerValues.push({ property: 'wins', direction: 'desc' } as ISortingRule);
        tieBreakerValues.push({ property: 'losses', direction: 'asc' } as ISortingRule);
        if (sortingRules && sortingRules.rules && sortingRules.rules.length) {
            let tieBreakerStats: ISortingRules[] = [];
            let firstHTHIndex = _.findIndex(sortingRules.rules, function (rule: ISortingRule) {
                return rule.property === 'hth' || rule.property === 'hth2o';
            });
            if (firstHTHIndex > 0) {
                tieBreakerValues = _.take(sortingRules.rules, firstHTHIndex);
            }
        }

        let ties: _.Dictionary<ITeamRegistration[]> = _.groupBy(teamregistrations, (tr: ITeamRegistration) => {
            let tieBreaker = '';
            _.each(tieBreakerValues, function (value: ISortingRule) {
                tieBreaker += tr[value.property] + ':';
            });
            return tieBreaker;
        });
        me.calculateHeadToHeadStandard(ties, sortingRules && sortingRules.firstGames);
        me.calculateHeadToHeadTwoOnly(ties, sortingRules && sortingRules.firstGames);
        teamregistrations = me.calculateGamesBack(teamregistrations);
        me.calculateOWPs(teamregistrations);
        me.calculateRPI(teamregistrations);
        return teamregistrations;
    }

    private calculateRPI(teams: ITeamRegistration[]) {
        let homeGameWeight = 0.6;
        let awayGameWeight = 1.4;
        _.each(teams, (team) => {
            let wins = (team.hw * homeGameWeight) + (team.aw * awayGameWeight) + (team.ties * .5);
            let losses = (team.hl * awayGameWeight) + (team.al * homeGameWeight);
            team.wwp = wins / (wins + losses + team.ties);
            team.oowp = this.calculateOOWP(teams, team);
            if (isNaN(team.wwp)) {
                team.wwp = 0;
            }
            team.rpi = (team.wwp * .25) + (team.owp * .5) + (team.oowp * .25);
            team.pwr = this.average([team.rpi, team.wlp])

        })
    }

    private getOtherTeams(teams: ITeamRegistration[], team: ITeamRegistration) {
        let otherTeams: string[] = [];
        _.each(team.awayGames, (game: IGame) => {
            if (game.winner) {
                otherTeams.push(game.homeTeamId);
            }
        });
        _.each(team.homeGames, (game: IGame) => {
            if (game.winner) {
                otherTeams.push(game.awayTeamId);
            }
        });
        return otherTeams;
    }

    private calculateOWPs(teams: ITeamRegistration[]) {
        _.each(teams, (team: ITeamRegistration) => {
            let opponents = this.getOtherTeams(teams, team);
            let opgp = 0;
            let opgw = 0;
            _.each(opponents, (opponent: string) => {
                let currentTeam = _.find(teams, (t) => {
                    return t.id == opponent;
                });
                _.each(currentTeam && currentTeam.awayGames, (game) => {
                    if (game.homeTeamId != team.id) {
                        if (game.winner) {
                            opgp++;

                            if (game.winner == currentTeam.id) {
                                opgw++;
                            }
                        }
                    }
                });
                _.each(currentTeam && currentTeam.homeGames, (game) => {
                    if (game.awayTeamId != team.id) {
                        if (game.winner) {
                            opgp++;
                            if (game.winner == currentTeam.id) {
                                opgw++;
                            }
                        }
                    }
                });
            });
            team.owp = opgw / opgp || 0;
        })
    }

    private calculateOOWP(teams: ITeamRegistration[], team: ITeamRegistration) {
        let otherTeams = this.getOtherTeams(teams, team);
        let oowp = [];
        _.each(otherTeams, (teamId) => {
            let innerTeam: ITeamRegistration = _.find(teams, (team: ITeamRegistration) => {
                return team.id == teamId;
            });
            if (innerTeam) {
                oowp.push(innerTeam.owp);
            }
        })

        return this.average(oowp);
    }

    public calculateStandingsOfLevels(teamLevels: ITeamLevel[], sortingRules?: ISortingRules) {
        let me = this;
        _.each(teamLevels, (teamLevel: ITeamLevel) => {
            _.each(teamLevel.divisions, (division: IDivision) => {
                division.teamregistrations = me.calculateStandingsOfTeamRegistrations(division.teamregistrations, sortingRules);
            });
        });
        return teamLevels;
    }

    public winningTeam(game: IGame): ITeamRegistration {
        if (!game || !game.homeTeam || !game.awayTeam) return null;
        if (game.homeTeamForfeit && game.awayTeam) return game.awayTeam;
        if (game.awayTeamForfeit && game.homeTeam) return game.homeTeam;
        if (game.homeTeamScore > game.awayTeamScore) return game.homeTeam;
        if (game.awayTeamScore > game.homeTeamScore) return game.awayTeam;
        if (game.awayTeam && game.awayTeam.byeTeam && game.homeTeam) return game.homeTeam;
        if (game.homeTeam && game.homeTeam.byeTeam && game.awayTeam) return game.awayTeam;
        return null;
    }
}
