export const ServicesModuleName: string = "league-manager-services";
import { NgModule } from "angular-ts-decorators";
import { AgeGroupService } from "./AgeGroupService";
import { DynamicStandingsAlgorithm } from "./DynamicStandingsAlgorithm";
import { GameEditorService } from "./GameEditorService";
import { LeagueService } from "./LeagueService";
import { OrganizationService } from "./OrganizationService";
import { PermissionsService } from "./PermissionsService";
import { SchedulingService } from "./SchedulingService";
import { GameService } from "./GameService";
import { SeasonService } from "./SeasonService";
import { SettingsService } from "./SettingsService";
import { SortingAlgorithmFactory } from "./SortingAlgorithmFactory";
import { SportsService } from "./SportsService";
import { StandingsService } from "./StandingsService";
import { TeamRegistrationService } from "./TeamRegistrationService";
import { TeamService } from "./TeamService";
import { TournamentsService } from "./TournamentsService";
import { UserService } from "./UserService";
import { AvailabilityService } from "./AvailabilityService";
import { EmailLogService } from "./EmailLogService";
import { RegistrationSystemService } from "./RegistrationSystemService";
import { PaymentService } from "./PaymentService";
import { TeamLevelService } from "./TeamLevelService";
import { CommonVanityUrlService } from "./VanityUrlService";
import { LeagueConfigService } from "./LeagueConfigService";

@NgModule({
  id: ServicesModuleName,
  declarations: [],
  providers: [
    AgeGroupService,
    DynamicStandingsAlgorithm,
    GameEditorService,
    LeagueService,
    OrganizationService,
    PermissionsService,
    SchedulingService,
    GameService,
    SeasonService,
    SettingsService,
    SortingAlgorithmFactory,
    SportsService,
    StandingsService,
    TeamRegistrationService,
    TeamService,
    TournamentsService,
    UserService,
    AvailabilityService,
    EmailLogService,
    RegistrationSystemService,
    PaymentService,
    TeamLevelService,
    CommonVanityUrlService,
    LeagueConfigService,
  ],
})
export class ServicesModule {
  constructor() { }
}
