import { Injectable } from "angular-ts-decorators";
import { ITeamLevel } from "../models/ITeamLevel";
import { IAgeGroup } from "../models/IAgeGroup";
import { IService } from "restangular";
import { IHttpService } from "angular";
import { IFilter } from "../models/IFilter";

@Injectable("AgeGroupService")
export class AgeGroupService {
  static $inject = ["Restangular", '$http'];
  constructor(private Restangular: IService, private $http: IHttpService) { }

  get(id: string): ng.IPromise<IAgeGroup> {
    return this.Restangular.one("agegroups", id).get();
  }

  update(agegroup: IAgeGroup): ng.IPromise<IAgeGroup> {
    return this.Restangular.one("agegroups", agegroup.id).customPATCH(agegroup);
  }

  remove(agegroup: IAgeGroup): ng.IPromise<IAgeGroup> {
    return this.Restangular.one("agegroups", agegroup.id).remove();
  }

  getTeamLevels(agegroup: string | IAgeGroup) {
    let ageGroupId: string;
    if (typeof agegroup === "string") {
      ageGroupId = agegroup;
    } else {
      ageGroupId = agegroup.id;
    }
    return this.$http.get<ITeamLevel[]>(`/api/agegroups/${ageGroupId}/teamlevels`).then(response => response?.data);
  }

  getTeamsForStandings(agegroup: string | IAgeGroup) {
    let ageGroupId: string;
    if (typeof agegroup === "string") {
      ageGroupId = agegroup;
    } else {
      ageGroupId = agegroup.id;
    }

    let filter: IFilter = {
      include: [
        {
          relation: "divisions",
          scope: {
            include: [
              {
                relation: "teamregistrations",
                scope: {
                  include: [
                    {
                      relation: "awayGames",
                      scope: {
                        include: [
                          "date",
                          {
                            relation: "awayTeam",
                            scope: {
                              include: {
                                relation: "team",
                                include: ["id", "name"]
                              }
                            }
                          },
                          {
                            relation: "homeTeam",
                            scope: {
                              include: {
                                relation: "team",
                                include: ["id", "name"]
                              }
                            }
                          }
                        ]
                      }
                    },
                    {
                      relation: "homeGames",
                      scope: {
                        include: [
                          "date",
                          {
                            relation: "awayTeam",
                            scope: {
                              include: {
                                relation: "team",
                                include: ["id", "name"]
                              }
                            }
                          },
                          {
                            relation: "homeTeam",
                            scope: {
                              include: {
                                relation: "team",
                                include: ["id", "name"]
                              }
                            }
                          }
                        ]
                      }
                    },
                    {
                      relation: "team",
                      scope: {
                        fields: ["name", "teamPhotoId", "urlKey"],
                        include: {
                          relation: "teamPhoto",
                          scope: {
                            fields: ["url"]
                          }
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    };
    return this.$http.get<ITeamLevel[]>(`/api/agegroups/${ageGroupId}/teamlevels?filter=${JSON.stringify(filter)}`).then(response => response?.data);
  }

}
