import { ITeamRegistration } from "../models/ITeamRegistration";
import { IRosterEntry } from "../models/IRosterEntry";
import { IMessage } from "../models/IMessage";
import { IDateTime } from "../models/IDateTime";
import { IPosition } from "../models/IPosition";
import { IGame } from "../models/IGame";
import { IFacility } from "../models/IFacility";
import { ICalendarEvent } from "../models/ICalendarEvent";
import { ILeagueScope } from "../models/ILeagueScope";
import { IFilter } from "../models/IFilter";
import { IPlayer } from "../models/IPlayer";
import { ITeam } from "../models/ITeam";
import { IEmailLog } from "../models/IEmailLog";
import { IDivision } from "../models/IDivision";
import { StandingsService } from "../services/StandingsService";
import { EmailLogService } from "../services/EmailLogService";
import { TeamRegistrationService } from "../services/TeamRegistrationService";
import { ISortingRules } from "../models/ISortingRules";
import { SettingsService } from "../services/SettingsService";
import { ISetting } from "../models/ISetting";
import { StateService } from "@uirouter/core";
import { IService, IElement, ICollection } from "restangular";
import _ from "lodash";
import moment from "moment";
import { ILeagueSetting } from "../models/ILeagueSetting";
import { IComponentController } from "angular";

export class TeamHomeController implements IComponentController {
  static $inject = [
    "$state",
    "$scope",
    "Restangular",
    "$timeout",
    "Upload",
    "notify",
    "$interval",
    "$uibModal",
    "$sce",
    "StandingsService",
    "SettingsService",
    "TeamRegistrationService",
    "EmailLogService",
  ];
  dataService: ICollection;
  registrationInfo: ITeamRegistration;
  registrationService: IElement;
  homeDateService: ICollection;
  rosterService: ICollection;
  loaded: boolean;
  newDate: string;
  newTime: string;
  newPlayer: IRosterEntry; // = <IRosterEntry>{player:<IPlayer>{}};
  newMessage: IMessage;
  selectedHomeDate: IDateTime;
  selectedRoster: IRosterEntry;
  positionTypes: any[];
  positionType: any;
  positionEmail: string;
  groupedPosition: _.Dictionary<IPosition[]>;
  selectedGame: IGame;
  newFacility: IFacility = {} as IFacility;
  addfacility: boolean;
  selectedFacility: IFacility;
  uploadedRosters: IRosterEntry[];
  /**
   * Calendar props and configuration
   */
  events: ICalendarEvent[] = [];
  eventSources: ICalendarEvent[][] = [];
  calendarConfig: any;
  teamSiteUrl: ISetting;

  rosterLogs: { [id: string]: IEmailLog } = {};

  constructor(
    private $state: StateService,
    private leagueScope: ILeagueScope,
    private Restangular: IService,
    private $timeout: ng.ITimeoutService,
    private Upload: angular.angularFileUpload.IUploadService,
    private notify: angular.cgNotify.INotifyService,
    private $interval: ng.IIntervalService,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private $sce: ng.ISCEService,
    private standingsService: StandingsService,
    private settingsService: SettingsService,
    private teamRegistrationService: TeamRegistrationService,
    private emailLogService: EmailLogService
  ) {
    let me = this;
    this.eventSources.push(this.events);
    this.calendarConfig = {
      calendar: {
        editable: false,
        header: {
          left: "title",
          center: "",
          right: "today prev,next",
        },
        eventClick(calEvent: ICalendarEvent, jsEvent, view) {
          me.calendarEventClick(calEvent, jsEvent, view);
        }, // proxy to call in context
      },
    };

    this.positionTypes = [
      {
        id: 1,
        label: "coach",
      },
      {
        id: 2,
        label: "team administrator",
      },
      {
        id: 3,
        label: "scorekeeper",
      },
    ];

    this.dataService = Restangular.all("teams");
    me.leagueScope.$watch(
      function () {
        return me.leagueScope.league.currentSeason;
      },
      function (newValue, oldValue) {
        if (newValue) {
          me.refresh();
        }
      },
      true
    );

    me.settingsService.getSetting("teamSiteUrl").then((setting: ISetting) => {
      me.teamSiteUrl = setting;
    });
  }

  $onInit() { }

  buildTeamSiteUrl(urlKey: string) {
    let me = this;
    if (!me.teamSiteUrl) return;
    return me.teamSiteUrl.value.replace("{urlKey}", urlKey);
  }

  refreshInterval: ng.IPromise<any>;
  refresh() {
    let me = this;
    me.loaded = false;
    this.dataService
      .customGET(
        me.$state.params.urlKey +
        "/" +
        this.leagueScope.league.currentSeason.id +
        "/home"
      )
      .then(function (teamRegistration: ITeamRegistration) {
        if (teamRegistration) {
          me.registrationInfo = teamRegistration;
          me.registrationService = me.Restangular.one(
            "teamregistrations",
            teamRegistration.id
          );
          me.homeDateService = me.registrationService.all("homedates");
          me.rosterService = me.registrationService.all("roster");
          _.each(me.registrationInfo.roster, function (roster: IRosterEntry) {
            roster.player.birthday = moment(
              roster.player.birthday as string
            ).toDate();
          });
          let unsignedRosterEntry: IRosterEntry[] = _.filter(
            me.registrationInfo.roster,
            (roster) => !roster.waiver
          );
          if (unsignedRosterEntry.length) {
            me.emailLogService
              .getAllRosterLogs(unsignedRosterEntry)
              .then((logs) => {
                _.each(me.registrationInfo.roster, (roster) => {
                  let userLogs = _.orderBy(
                    _.filter(
                      logs,
                      (log) => log?.rosterId == roster.id
                    ) as IEmailLog[],
                    (log) => log?.response?.mail?.timestamp
                  );
                  if (userLogs.length) {
                    me.rosterLogs[roster.id] = userLogs[0];
                  }
                });
              });
          }

          me.registrationInfo.team.groupedPositions = _.groupBy(
            teamRegistration.team.positions,
            function (item: IPosition) {
              return item.positionType;
            }
          );
          me.registrationInfo.allGames = me.registrationInfo.awayGames.concat(
            me.registrationInfo.homeGames
          );
          me.loadDivisionStandings(teamRegistration.divisionId);
          me.selectedFacility =
            me.registrationInfo.team.homeFields.length &&
            me.registrationInfo.team.homeFields[0];
          let needsScheduling = false;
          _.each(me.registrationInfo.awayGames, function (game: IGame) {
            if (!game.date) {
              needsScheduling = true;
            } else {
              let startDate = moment(game.date.datetime).toDate();
              let endDate = new Date(startDate.getTime());
              endDate.setTime(endDate.getTime() + 3 * 60 * 60 * 1000);
              if (!game.isTournamentMatch) {
                me.events.push({
                  title: "@" + game.homeTeam.team.name,
                  start: startDate,
                  end: endDate,
                  stick: true,
                  game,
                });
              }
            }
            if (me.$state.params.gameId === game.id) me.selectedGame = game;
          });
          _.each(me.registrationInfo.homeGames, function (game) {
            if (game.date) {
              let startDate = moment(game.date.datetime).toDate();
              let endDate = new Date(startDate.getTime());
              endDate.setTime(endDate.getTime() + 3 * 60 * 60 * 1000);
              if (!game.isTournamentMatch) {
                me.events.push({
                  title: "vs. " + game.awayTeam.team.name,
                  start: startDate,
                  end: endDate,
                  stick: true,
                  game,
                });
              }
            }
            if (me.$state.params.gameId === game.id) me.selectedGame = game;
          });
          if (
            me.isManager() &&
            needsScheduling &&
            me.registrationInfo.ageGroup.schedulingOpen
          ) {
            me.refreshInterval = me.$interval(function () {
              let filter: IFilter = {
                include: [
                  { awayTeam: ["team", { homeDates: "facility" }] },
                  { homeTeam: ["team", { homeDates: "facility" }] },
                  { date: "facility" },
                  "facility",
                ],
              };
              me.registrationService
                .all("awayGames")
                .getList({ filter })
                .then(function (awayGames) {
                  // me.registrationInfo.awayGames = awayGames;
                  _.each(me.registrationInfo.awayGames, function (game: IGame) {
                    let sameGame: IGame = _.find(
                      awayGames,
                      function (g: IGame) {
                        return g.id === game.id;
                      }
                    );
                    game.homeTeam.homeDates = sameGame.homeTeam.homeDates;
                  });
                });
            }, 10000);
            me.leagueScope.$on("$destroy", function () {
              me.$interval.cancel(me.refreshInterval);
              me.refreshInterval = undefined;
            });
          }
        } else {
          me.registrationInfo = null;
        }
        me.loaded = true;
      })
      .catch(function (err) {
        me.loaded = true;
      });
  }

  hasDateFilter(value: IGame) {
    return value.date != null;
  }

  noDateFilter(value: IGame) {
    return value.date == null;
  }

  isManager() {
    return (
      (this.leagueScope.league.roleInfo &&
        this.leagueScope.league.roleInfo.isAdmin) ||
      (this.leagueScope.league.roleInfo &&
        this.leagueScope.league.roleInfo.isAgeGroupDirector) ||
      (this.leagueScope.league.userInfo &&
        this.registrationInfo &&
        this.registrationInfo.team &&
        this.leagueScope.league.userInfo.id ===
        this.registrationInfo.team.managerId) ||
      this.isTeamDirector()
    );
  }

  isTeamDirector() {
    let me = this;
    let teamDirector = false;
    if (!this.registrationInfo || !this.registrationInfo.team)
      return teamDirector;
    _.each(this.registrationInfo.team.positions, function (position) {
      if (
        me.leagueScope.league.userInfo &&
        me.leagueScope.league.userInfo.id === position.userId &&
        position.positionType === "team administrator"
      ) {
        teamDirector = true;
      }
    });
    return teamDirector;
  }

  isScoreKeeper() {
    let me = this;
    let scoreKeeper = false;
    if (!this.registrationInfo || !this.registrationInfo.team)
      return scoreKeeper;
    _.each(this.registrationInfo.team.positions, function (position) {
      if (
        me.leagueScope.league.userInfo &&
        me.leagueScope.league.userInfo.id === position.userId &&
        position.positionType === "scorekeeper"
      ) {
        scoreKeeper = true;
      }
    });
    return me.isManager() || scoreKeeper;
  }

  isTeamMember() {
    let me = this;
    let isStaff = false;
    _.each(this.registrationInfo.team.positions, function (position) {
      if (
        me.leagueScope.league.userInfo &&
        me.leagueScope.league.userInfo.id === position.userId &&
        position.positionType === "team administrator"
      ) {
        isStaff = true;
      }
    });
    return this.isManager();
  }

  canSeeContactInformation() {
    return this.isManager();
  }

  uploadPhoto(file) {
    if (!file) return;
    let me = this;
    let fileUploadConfig: angular.angularFileUpload.IFileUploadConfigFile = {
      method: "POST",
      data: { file },
      url: "api/files/upload",
    };
    file.upload = me.Upload.upload(fileUploadConfig);
    file.upload.then(function (response) {
      me.$timeout(function () {
        me.Restangular.one("teams", me.registrationInfo.team.id)
          .customPATCH({ teamPhotoId: response.data.id })
          .then(function (team) {
            me.registrationInfo.team.teamPhoto = response.data;
          });
      });
    });
  }

  tempRoster: any;
  uploadRoster(file) {
    if (!file) return;
    let me = this;
    me.uploadedRosters = [];
    let fileUploadConfig: angular.angularFileUpload.IFileUploadConfigFile = {
      method: "POST",
      data: { file },
      url: "api/files/convertcsv",
    };
    file.upload = me.Upload.upload(fileUploadConfig);
    file.upload.then(function (response) {
      _.each(response.data, function (r: any) {
        me.uploadedRosters.push({
          player: {
            firstName: r.firstname,
            lastName: r.lastname,
            guardianemail: r.guardianemail,
            birthday: r.birthday,
            phoneNumber: r.phonenumber,
            grade: r.grade,
          } as IPlayer,
          number: r.number,
        } as IRosterEntry);
      });
    });
  }

  processing: boolean;
  saveUploadedRosters(rosters: IRosterEntry[]) {
    let me = this;
    me.processing = true;
    let total = 0;
    _.each(rosters, function (roster) {
      me.rosterService.post<IRosterEntry>(roster).then(function (result) {
        let idx = rosters.indexOf(roster);
        rosters.splice(idx, 1);
        total++;
        result.player.birthday = new Date(result.player.birthday as string);
        me.registrationInfo.roster.push(result);
        if (total >= rosters.length) {
          me.processing = false;
          me.$state.go("league.team.rostermanager.add");
        }
      });
    });
  }

  addHomeDate() {
    let me = this;
    let homeDate: IDateTime = {
      date: this.newDate,
      time: this.newTime,
      facilityId: this.selectedFacility.id,
    } as IDateTime;
    homeDate.datetime = moment(
      this.newDate + " " + this.newTime,
      "YYYY-MM-DD h:mmA"
    ).toDate();
    this.homeDateService.post<IDateTime>(homeDate).then(function (result) {
      result.facility = me.selectedFacility;
      me.registrationInfo.homeDates.push(result);
      me.newDate = null;
      me.newTime = null;
    });
  }

  editHomeDate(homeDate: IDateTime) {
    this.selectedHomeDate = homeDate;
    this.$state.go("league.team.homedates.edit");
  }

  saveHomeDate() {
    let me = this;
    this.selectedHomeDate.datetime = moment(
      this.selectedHomeDate.date + " " + this.selectedHomeDate.time,
      "YYYY-MM-DD h:mmA"
    ).toDate();
    this.selectedHomeDate.facilityId = this.selectedHomeDate.facility.id;
    this.homeDateService
      .customPUT(this.selectedHomeDate, this.selectedHomeDate.id)
      .then(function (homeDate) {
        me.$state.go("league.team.homedates.add");
      });
  }

  removeHomeDate(homeDate: IDateTime) {
    if (!confirm("Are you sure you want to delete this home date?")) return;
    let me = this;
    let dateService: IElement = me.registrationService.one(
      "homedates",
      homeDate.id
    );
    dateService.remove().then(function (result) {
      let idx = me.registrationInfo.homeDates.indexOf(homeDate);
      me.registrationInfo.homeDates.splice(idx, 1);
    });
  }

  addRoster() {
    let me = this;
    this.rosterService
      .post<IRosterEntry>(this.newPlayer)
      .then(function (result) {
        result.player.birthday = new Date(result.player.birthday as string);
        me.registrationInfo.roster.push(result);
        me.newPlayer = null;
      });
  }

  editRoster(roster: IRosterEntry) {
    this.selectedRoster = roster;
    this.$state.go("league.team.rostermanager.edit");
  }

  saveRoster() {
    let me = this;
    this.rosterService
      .customPUT(this.selectedRoster, this.selectedRoster.id)
      .then(function () {
        me.$state.go("league.team.rostermanager.add");
      });
  }

  updateBirthday(value: Date) {
    this.selectedRoster.player.birthday = value;
  }

  removeRoster(roster: IRosterEntry) {
    if (!confirm("Are you sure you want to delete this roster entry?")) return;
    let me = this;
    let rosterService: IElement = me.registrationService.one(
      "roster",
      roster.id
    );
    rosterService.remove().then(function (result) {
      let idx = me.registrationInfo.roster.indexOf(roster);
      me.registrationInfo.roster.splice(idx, 1);
    });
  }

  setAllStar(roster: IRosterEntry) {
    this.rosterService
      .customPUT({ allstarNomination: !roster.allstarNomination }, roster.id)
      .then(function (response) {
        roster.allstarNomination = response.allstarNomination;
      });
  }

  addPosition() {
    let me = this;
    let svc = this.Restangular.one("teams", this.registrationInfo.team.id).all(
      "addposition"
    );
    svc
      .post({
        positionType: this.positionType.label,
        email: this.positionEmail,
        leagueId: me.leagueScope.league.leagueData.id,
      })
      .then(function (position) {
        me.registrationInfo.team.positions.push(position);
        me.registrationInfo.team.groupedPositions = _.groupBy(
          me.registrationInfo.team.positions,
          function (item: IPosition) {
            return item.positionType;
          }
        );
      });
  }

  deletePosition(position) {
    if (!confirm("are you sure you would like to delete this position?"))
      return;
    let me = this;
    this.Restangular.one("teams", this.registrationInfo.team.id)
      .one("positions", position.id)
      .remove()
      .then(function () {
        let idx = me.registrationInfo.team.positions.indexOf(position);
        me.registrationInfo.team.positions.splice(idx, 1);
        me.registrationInfo.team.groupedPositions = _.groupBy(
          me.registrationInfo.team.positions,
          function (item: IPosition) {
            return item.positionType;
          }
        );
      });
  }

  selectGame(game: IGame) {
    this.selectedGame = game;
  }

  setGameDate(game: IGame, date: IDateTime) {
    let me = this;
    me.$uibModal
      .open({
        template: require("../templates/modals/confirmgame.html").default,
      })
      .result.then(function (result) {
        if (result) {
          me.confirmedGameDate(game, date);
        }
      });
  }

  confirmedGameDate(game: IGame, date: IDateTime) {
    let me = this;
    this.Restangular.one("games", game.id)
      .customPATCH({ dateId: date.id }, "setdate")
      .then(function (updatedGame) {
        game.date = date;
        game.dateId = date.id;
        me.$state.go("league.team.game.info", { gameId: game.id });
        /*me.Restangular.one('datetimes', date.id).customPATCH({taken:true})
                .then(function (updatedGame:IDateTime) {
                    date.taken = true;

                })*/
      })
      .catch(function (error) {
        me.notify({
          message: "it appears that date was already taken",
          classes: "message-danger",
        });
      });
  }

  changeLocation(team: ITeam, game: IGame, facility: IFacility) {
    let me = this;
    me.Restangular.one("datetimes", game.date.id)
      .customPATCH({ facilityId: facility.id })
      .then(function () {
        game.date.facility = facility;
        game.date.facilityId = facility.id;
        me.Restangular.one("games", game.id)
          .customPATCH({ dateId: game.date.id, officialAssigned: false })
          .then(function () {
            me.$state.go("league.team.game.info");
          });
      });
  }

  addHomeField(team: ITeam, facility: IFacility) {
    let me = this;
    this.Restangular.one("teams", team.id)
      .all("homeFields")
      .post(facility)
      .then(function (addedFacility) {
        me.registrationInfo.team.homeFields.push(addedFacility);
        me.newFacility = {} as IFacility;
        me.addfacility = false;
      });
  }

  setEditing(facility: IFacility, editing: boolean) {
    if (!facility.__uistate) {
      facility.__uistate = {};
    }
    facility.__uistate.editing = editing;
  }

  editFacility(facility: IFacility) {
    this.setEditing(facility, true);
  }

  deleteFacility(team: ITeam, facility: IFacility) {
    if (!confirm("are you sure you would like to delete this facility?"))
      return;
    let me = this;
    this.Restangular.one("teams", team.id)
      .one("homeFields", facility.id)
      .remove()
      .then(function () {
        let idx = me.registrationInfo.team.homeFields.indexOf(facility);
        me.registrationInfo.team.homeFields.splice(idx, 1);
      });
  }

  updateHomeField(team: ITeam, facility: IFacility) {
    let me = this;
    this.Restangular.one("teams", team.id)
      .one("homeFields", facility.id)
      .customPUT(facility)
      .then(function (updatedFacility) {
        me.setEditing(facility, false);
      });
  }

  updateScore(game: IGame) {
    let me = this;
    this.Restangular.one("games", game.id)
      .customPATCH({
        homeTeamScore: game.homeTeamScore,
        awayTeamScore: game.awayTeamScore,
        homeTeamForfeit: game.homeTeamForfeit,
        awayTeamForfeit: game.awayTeamForfeit,
      })
      .then(function () {
        me.$state.go("league.team.home");
      });
  }

  winLossOrTie(thisTeam: ITeamRegistration, game: IGame) {
    if (thisTeam.id === game.homeTeamId) {
      if (game.awayTeamForfeit || game.homeTeamForfeit) {
        return game.awayTeamForfeit ? "(w)" : "(l)";
      }
      return this.calculateWinner(game.homeTeamScore, game.awayTeamScore);
    } else if (thisTeam.id === game.awayTeamId) {
      if (game.awayTeamForfeit || game.homeTeamForfeit) {
        return game.homeTeamForfeit ? "(w)" : "(l)";
      }
      return this.calculateWinner(game.awayTeamScore, game.homeTeamScore);
    }
  }

  calculateWinner(myTeamScore: number, opponentScore: number) {
    if (myTeamScore != null && opponentScore != null) {
      if (myTeamScore > opponentScore) {
        return "(w)";
      } else if (myTeamScore < opponentScore) {
        return "(l)";
      } else if (myTeamScore === opponentScore) {
        return "(t)";
      }
    }
  }

  addMessage(message: IMessage) {
    let me = this;
    me.Restangular.one("teamregistrations", me.registrationInfo.id)
      .all("messages")
      .post(message)
      .then(function (newMessage) {
        me.registrationInfo.messages.push(newMessage);
        me.$state.go("league.team.home");
      });
  }

  deleteMessage(message: IMessage) {
    if (!confirm("are you sure you would like to delete this message?")) return;
    let me = this;
    me.Restangular.one("teamregistrations", me.registrationInfo.id)
      .one("messages", message.id)
      .remove()
      .then(function (response) {
        let idx = me.registrationInfo.messages.indexOf(message);
        me.registrationInfo.messages.splice(idx, 1);
      });
  }

  sendWaivers() {
    let me = this;
    me.Restangular.one("teamregistrations", me.registrationInfo.id)
      .customGET("sendwaivers")
      .then(function (success) {
        me.notify({
          message: "roster emails successfully sent",
          classes: "message-success",
        });
      });
  }

  updateHeadCoach(team: ITeam, email: string) {
    if (
      !confirm(
        "Changing the manager will remove your access to the team unless you are assigned as the team administrator, are you sure?"
      )
    )
      return;
    let me = this;
    me.Restangular.one("teams", team.id)
      .customPOST(
        { email, leagueId: me.leagueScope.league.id },
        "updatemanager"
      )
      .then(function (updatedTeam) {
        me.refresh();
        me.$state.go("league.team.home");
      });
  }

  division: IDivision;

  loadDivisionStandings(divisionId: string) {
    if (!divisionId) return;
    let filter: IFilter = {
      include: {
        teamregistrations: ["awayGames", "homeGames", { team: "teamPhoto" }],
      },
    };
    let me = this;
    me.Restangular.one("divisions", divisionId)
      .get({ filter })
      .then(function (division: IDivision) {
        division.teamregistrations =
          me.standingsService.calculateStandingsOfTeamRegistrations(
            division.teamregistrations,
            { excludeTournaments: true } as ISortingRules
          );
        me.division = division;
      });
  }

  postponeGame(game: IGame) {
    let me = this;
    me.$uibModal
      .open({
        template: require("../templates/team/modals/postponegame.html").default,
      })
      .result.then(function (result) {
        if (result) {
          me.postponeGameResult(game, result);
        }
      });
  }

  postponeGameResult(game: IGame, message: string) {
    let me = this;
    me.Restangular.one("games", game.id)
      .customPATCH({
        postponed: true,
        postponeReason: message,
      })
      .then(function (result) {
        game.postponed = true;
        game.postponeReason = message;
      });
  }

  calendarEventClick(calEvent: ICalendarEvent, jsEvent, view) {
    let me = this;
    me.selectedGame = calEvent.game;
    me.$state.go("league.team.game.info", { gameId: calEvent.game.id });
  }

  openHelp() {
    let me = this;
    me.$uibModal.open({
      template: require("../templates/team/modals/calendar.help.html").default,
    });
  }

  subscribeUrl() {
    return (
      window.location.hostname +
      "/api/teams/" +
      this.$state.params.urlKey +
      "/" +
      this.leagueScope.league.currentSeason.id +
      "/subscribe"
    );
  }

  signedRosters(rosters: IRosterEntry[]) {
    return _.filter(rosters, (roster) => {
      return roster.waiver;
    });
  }
  allRostersSigned(rosters: IRosterEntry[]) {
    let rostersWithWaivers = this.signedRosters(rosters);
    return rostersWithWaivers.length === (rosters && rosters.length);
  }

  canSchedule(settings: ILeagueSetting) {
    if (settings.restrictScheduling) {
      if (this.registrationInfo && this.registrationInfo.earlyScheduling) {
        // short circuit the logic if early scheduling is enabled
        return true;
      }
      let rosterWithWaivers = _.filter(
        this.registrationInfo && this.registrationInfo.roster,
        (roster: IRosterEntry) => {
          return roster.waiver;
        }
      );
      if (this.registrationInfo.team.homeFields?.length) {
        if (this.registrationInfo.team.homeFields?.length >= settings.minimumFacilities) {
          if (
            rosterWithWaivers.length ===
            (this.registrationInfo && this.registrationInfo.roster.length)
          ) {
            if (
              settings.minimumRoster &&
              settings.minimumRoster > 0 &&
              this.registrationInfo &&
              this.registrationInfo.roster.length >= (settings.minimumRoster || -1)
            ) {
              return (
                this.registrationInfo &&
                this.registrationInfo.homeDates.length >=
                (settings.minimumHomeDates || -1)
              );
            }
          }
        }
      }

      return false;
    }
    return true;
  }
}
