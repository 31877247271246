import { UIComponent } from "../../../core/UIComponent";
import { Component, Input } from "angular-ts-decorators";
import template from "./contacts.html";
import "./contacts.scss";
import { ISeason } from "../../../models/ISeason";
import { IAgeGroup } from "../../../models/IAgeGroup";
import { SeasonService } from "../../../services/SeasonService";
import { ITeamRegistrationGroup } from "../../../models/ITeamRegistrationGroup";
import _ from "lodash";
import { ITeamRegistration } from "../../../models/ITeamRegistration";
import { ILeague } from "../../../models/ILeague";

const name: string = "contacts";

@Component({
  selector: name,
  template,
})
export class LeagueContactsComponent extends UIComponent {
  @Input() season: ISeason;
  @Input() agegroups: IAgeGroup[];
  @Input() league: ILeague;

  static Name: string = name;
  static $inject: string[] = ["SeasonService", "$sce"];

  private ageGroup: IAgeGroup;

  private teams: ITeamRegistration[];

  private teamReport: any;

  public csvContent: string;

  public allColumns = [
    { field: "team", label: "team" },
    { field: "agegroup", label: "age group" },
    { field: "level", label: "level" },
    { field: "type", label: "type" },
    { field: "firstName", label: "first name" },
    { field: "lastName", label: "last name" },
    { field: "email", label: "email" },
    { field: "phone", label: "phone" },
    { field: "homePhone", label: "home phone" },
    { field: "birthday", label: "birthday" },
    { field: "grade", label: "grade" },
    { field: "jersey", label: "jersey" },
    { field: "membership_id", label: "membership id" },
    { field: "emergency_first_name", label: "emergency first" },
    { field: "emergency_last_name", label: "emergency last" },
    { field: "emergency_email", label: "emergency email" },
    { field: "emergency_phone", label: "emergency phone" },
    { field: "emergency_relation", label: "emergency relation" },
  ]

  public selectedColumns = [
    ...this.allColumns.slice(0, 9)
  ]

  constructor(
    public seasonService: SeasonService,
    public $sce: angular.ISCEService
  ) {
    super();
  }

  $onInit() { }

  selectAgeGroup(ageGroup: IAgeGroup) {
    this.ageGroup = ageGroup;
    this.loadTeamData(ageGroup);
  }

  async loadTeamData(ageGroup: IAgeGroup) {
    this.teams = await this.seasonService.getTeams(this.season, ageGroup);
    this.buildReport();
  }

  buildReport() {
    this.teamReport = [];
    _.each(this.teams, (registration: ITeamRegistration) => {
      if (!registration || !registration.team) return;
      let reportRow = {
        team: registration.team.name || "",
        agegroup: registration.ageGroup.name || "",
        level: registration.teamLevel.name || "",
        type: "manager",
        firstName:
          (registration.team.manager && registration.team.manager.firstName) ||
          "",
        lastName:
          (registration.team.manager && registration.team.manager.lastName) ||
          "",
        email:
          (registration.team.manager && registration.team.manager.email) || "",
        phone:
          (registration.team.manager && registration.team.manager.cellPhone) ||
          "",
        homePhone:
          (registration.team.manager && registration.team.manager.homePhone) ||
          "",
      };
      this.teamReport.push(reportRow);

      _.each(registration.team.positions, (position) => {
        if (!position || !position.user) return;
        try {
          let positionReportRow = {
            team: registration.team.name || "",
            agegroup: registration.ageGroup.name || "",
            level: registration.teamLevel.name || "",
            type: position.positionType || "",
            firstName: position.user.firstName || "",
            lastName: position.user.lastName || "",
            email: position.user.email || "",
            phone: position.user.cellPhone || "",
            homePhone: position.user.homePhone || "",
          };
          this.teamReport.push(positionReportRow);
        } catch (ex) { }
      });

      _.each(registration.roster, (roster) => {
        if (!roster || !roster.player) return;
        try {
          let rosterReportRow = {
            team: registration.team.name || "",
            agegroup: registration.ageGroup.name || "",
            level: registration.teamLevel.name || "",
            type: "player",
            firstName: roster.player.firstName || "",
            lastName: roster.player.lastName || "",
            email: roster.player.guardianemail || "",
            phone: roster.player.phoneNumber || "",
            homePhone: "",
            birthday: roster.player.birthday || "",
            grade: roster.player.grade || "",
            jersey: roster.number || "",
            membership_id: roster.player.membershipId || "",
            emergency_first_name: roster.emergencyContact?.firstName || "",
            emergency_last_name: roster.emergencyContact?.lastName || "",
            emergency_email: roster.emergencyContact?.email || "",
            emergency_phone: roster.emergencyContact?.cellPhone || "",
            emergency_relation: roster.emergencyContact?.relationToPlayer || "",

          };
          this.teamReport.push(rosterReportRow);
        } catch (ex) { }
      });
    });
    this.buildCsv();
  }

  buildCsv() {
    let cols = [
      ...this.selectedColumns.map((col) => { return col.field })
    ];
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += cols.join(",") + "\r\n";
    this.teamReport.forEach((row: any) => {
      let values = [];
      for (let col of cols) {
        values.push(row[col]);
      }
      csvContent += values.join(",") + "\r\n";
    });
    this.csvContent = encodeURI(csvContent);
  }

  download() {
    if (!this.csvContent) return;
    let link = document.createElement("a");
    link.setAttribute("href", this.csvContent);
    link.setAttribute(
      "download",
      `export_${this.season.name}_${this.ageGroup && this.ageGroup.name}.csv`
    );
    document.body.appendChild(link);

    link.click();
  }
}
