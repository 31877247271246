import { ISetting } from '../models/ISetting';
import { IFilter } from '../models/IFilter';
import { Injectable } from 'angular-ts-decorators';
import { IService } from 'restangular';
import { IHttpService } from 'angular';
import { update } from 'lodash';
import { stringify } from 'querystring';

export interface LeagueConfig {
  id?: string;
  leagueId?: string;
  key: string;
  value: any;
}

@Injectable('LeagueConfigService')
export class LeagueConfigService {
  static $inject: string[] = ['$http'];

  constructor(private http: IHttpService) {
  }

  list(leagueId: string) {
    return this.http.get<LeagueConfig[]>(`/api/leagues/${leagueId}/configs`).then((res) => res.data);

  }

  getByKey(leagueId: string, key: string) {
    const filter: IFilter = {
      where: {
        key
      }
    }
    return this.http.get<LeagueConfig[]>(`/api/leagues/${leagueId}/configs?filter=${JSON.stringify(filter)}`).then((res) => res.data[0]);
  }

  add(leagueId: string, config: LeagueConfig) {
    return this.http.post<LeagueConfig>(`/api/leagues/${leagueId}/configs`, config).then((res) => res.data);
  }

  update(config: LeagueConfig) {
    return this.http.put<LeagueConfig>(`/api/leagues/${config.leagueId}/configs/${config.id}`, config).then((res) => res.data);
  }

}
